<template class="pa-0">
    
<v-container class="pa-0 mb-4">

    <v-toolbar flat>
        <v-toolbar-title>Historial
      
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-flex  xs4 sm4 md2 lg2  class="mt-2">
             <v-select v-model="id_vehiculo_vista" :items="selectVehiculo" small 
                         style="width:150px;height:30px;padding-top:0px;"
                        class="text-caption"
                        label="Vehiculo">
                        </v-select>
        </v-flex>

         <v-flex  xs4 sm4 md2 lg2  class="mt-6">
             <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="260px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              style="width:120px;"
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
         </v-flex>




 <v-flex  xs4 sm4 md2 lg2  class="mt-6 ml-n8">
       <v-menu
                            ref="menu"
                            v-model="menu_hora"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            color="indigo"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              style="width:110px;"
                                v-model="hora_inicio"
                                label="Hora inicio"
                                prepend-icon="timer"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            color="indigo"
                            v-if="menu_hora"
                            v-model="hora_inicio"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>
</v-flex>



        <v-flex  xs4 sm4 md2 lg2  class="mt-6 ml-n7">
              <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="260px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                           style="width:120px;"
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>
              </v-flex>




               <v-flex  xs4 sm4 md2 lg2  class="mt-6 ml-n8">
       <v-menu
                            ref="menu"
                            v-model="menu_hora_esta"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            color="indigo"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              style="width:110px;"
                                v-model="hora_fin"
                                label="Hora fin"
                                prepend-icon="timer"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            color="indigo"
                            v-if="menu_hora_esta"
                            v-model="hora_fin"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>
</v-flex>


            <v-switch class="mr-5 mt-5"
                  v-model="switch1"
                  :label="switchLabel"
                  @change="cambiarFormatoTemp()"
                ></v-switch>


 
        <v-btn color="success" @click="Procesar()" class="mt-1 ml-8"> 
                  <v-icon dark>find_in_page</v-icon>
                </v-btn>


    </v-toolbar>


    <v-row class="mt-5">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span>Temperatura</span>

            <v-chip color="red" class="text--white mx-5"> <v-icon dark>arrow_upward</v-icon> Max: {{ temp_max }} </v-chip>
            <v-chip color="primary"> <v-icon>arrow_downward</v-icon>Min:{{ temp_min }}</v-chip>
            <v-chip color="success mx-5"><v-icon>arrow_right_alt</v-icon>Avg:{{ temp_avg }}</v-chip>

            <v-checkbox class="ml-4"
            v-model="selectedEncendido"
            label="Encendido"
            @change="VerEncendido()"></v-checkbox>

             <v-checkbox
            v-model="selectedCompuerta"
            label="Puerta Abierta"
            @change="VerCompuerta()"></v-checkbox>



          </v-card-title>
        <div id="chart">
          <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>

       

      </v-card>

      </v-col>


      <v-col cols="12">

         <v-card>


                <v-toolbar flat color="info">


                <v-toolbar-title class="white--text">
                Detalle
                </v-toolbar-title>

                <v-divider  class="mx-4" inset vertical></v-divider>

                <vue-excel-xlsx v-show="arregloTemperatura.length >0" class="ml-4"
                                    :data="arregloTemperatura"
                                    :columns="column_excel"
                                    :file-name="'temperatura'"
                                    :sheet-name="'hoja1'"
                                    :file-type="'xlsx'">
                                    <v-btn color="success">
                                        <v-icon dark>get_app</v-icon>
                                    </v-btn>
                                
                        </vue-excel-xlsx>

                </v-toolbar>

          <div class="table-container">

          <v-data-table
            :headers="headers"
            :items="arregloTemperatura"
             fixed-header
          hide-default-footer
          disable-pagination
          class="flex-table"
          height="60vh"
            >

            <template v-slot:[`item.ubicacion`]="{ item }">
            <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
            </template>

            <template v-slot:[`item.fecha`]="{ item }">
              <span>{{ item.fecha }}</span>
             </template>


             <template v-slot:[`item.status`]="{ item }">

                <v-chip  v-if="item.estado_encendido==true || item.estado_encendido==1" color="green"   text-color="white" x-small>
                   <v-icon left dark class="mx-2">mdi-engine</v-icon> On
                </v-chip>

                 <v-chip  v-else color="gray"   text-color="white" x-small>
                   <v-icon left dark class="mx-2">mdi-engine-off-outline</v-icon> Off
                </v-chip>

                <v-chip v-if="item.estado_compuerta==true" color="red"   text-color="white" x-small>
                   <v-icon left dark class="mx-2">mdi-door-open</v-icon> Open
                </v-chip>

                <v-chip  v-else color="gray"   text-color="white" x-small>
                   <v-icon left dark class="mx-2">mdi-door-closed-lock</v-icon> Close
                </v-chip>
    
              <span v-if="item.status">{{ item.status }}</span>

          </template>



          <template v-slot:[`item.s1`]="{ item }">
             
             <v-chip v-if="limite_max_veh!='' && item.s1 > limite_max_veh" color="red" small class="white--text"> {{ item.s1 }}</v-chip>
             <span v-else>{{ item.s1 }}</span>
             </template> 

              <template v-slot:[`item.s2`]="{ item }">
              <v-chip v-if=" limite_max_veh!='' && item.s2 > limite_max_veh" color="red" small class="white--text"> {{ item.s2 }}</v-chip>
              <span v-else>{{ item.s2 }}</span>
              </template>   

            <template v-slot:[`item.s3`]="{ item }">
              <v-chip v-if="limite_max_veh!='' && item.s3 > limite_max_veh" color="red" small class="white--text">{{ item.s3 }}</v-chip>
              <span v-else>{{ item.s3 }}</span>
            </template>   




          </v-data-table>

          </div>

            </v-card>
           

      </v-col>

    </v-row>


</v-container>
      
</template>


<script>


import xlsx from "json-as-xlsx";
import axios from 'axios';
import { mapMutations, mapState } from "vuex";
import VueApexCharts from 'vue-apexcharts';

 export default {
    name:'Historicos',
             components:{
            apexchart: VueApexCharts
             },
data: () => ({

    //opciones del dialogo de fechas
        menu: false,
        dialogInicio:true,
        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

        id_vehiculo_vista:'',
        selectVehiculo:[],

        time: null,
        menu_hora:false,
        menu_hora_esta:false,
        hora_inicio:'00:00',
        hora_fin:'23:59',
        errorMessages:'',
        rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           }

       },

        series:[{name:"s1",data:[1]},{name:"s2",data:[1]},{name:"s3", data:[1]} ],

         chartOptions: {
            chart: {
              height: 350, type: 'line',
              dropShadow: {
                enabled: true, color: '#000',
                top: 18,left: 7, blur: 10, opacity: 0.2
              },
              toolbar: { show: false}
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: { enabled: true },
            stroke: {  curve: 'smooth'},
           // title: { text: 'Temperatura', align: 'left'},
            grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
            markers: { size: 1 },
            xaxis: {
              categories: ['08:00'], title: { text: 'Hora' }
            },
            yaxis: {
              title: { text: 'Temperatura' },
              min: -20, max: 40
            },
            legend: {
              position: 'top', horizontalAlign: 'right', floating: true,
              offsetY: -25,  offsetX: -5
            }
          },

          arregloTemperatura:[],
          temp_max:0,
          temp_min:0,
          temp_avg:0,


          column_excel:[
               {label: "Nombre",  field: "id_po_vehiculo.nombre"},
              {label: "Placa",  field: "id_po_vehiculo.placa"},
              {label: "Flota",  field: "id_po_vehiculo.flota"},
              {label: "Fecha",  field: "fecha"},
              {label:"Encendido", field:"estado_encendido"},
              {label:"Puerta", field:"estado_compuerta"},
              {label: "Latitud",  field: "latitud"},
              {label: "Longitud",  field: "longitud"},
              {label: "S1",  field: "s1"},
              {label: "S2",  field: "s2"},
              {label: "S3",  field: "s3"}

              ],
          headers:[
                            { text:  'Vehiculo', value: 'id_po_vehiculo.nombre' },
                            { text:  'Placa', value: 'id_po_vehiculo.placa' },
                            { text:  'Flota', value: 'id_po_vehiculo.flota' },
                            { text: 'Status', value: 'status'},
                            { text:  'Fecha', value: 'fecha' },
                            { text:  'Latitud', value: 'latitud' },
                            { text:  'Longitud', value: 'longitud' },

                            { text:  'Ubicacion', value:  'ubicacion'},

                            { text:  'S1', value: 's1' },
                            { text:  'S2', value: 's2' },
                            { text:  'S3', value: 's3' },
                          
          ],

          arreglo_camiones:[], 
          limite_max_veh:'',
          limite_min_veh:'',

          arreglo_encendidos:[],
          arreglo_compuerta:[],

          selectedEncendido:true,
          selectedCompuerta:true,
          labels:[],

          switch1:true,
          switchLabel:'°C',


}),

computed: {

    ...mapState(['usuario', 'token']),

      dynamicSize() {
      return [this.iconSize, this.iconSize * 4];
      },
      dynamicAnchor() {
      return [this.iconSize / 1, this.iconSize * 2];
      },
},

created () {

     this.listarVehiculos();
     this.$root.$refs.Home.detenerTimer();

    },
methods: {

            ...mapMutations(['mostrarLoading','ocultarLoading']),

            listarVehiculos(){

                
                 this.selectVehiculo=[];
                 this.arreglo_camiones=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);

                        let respuesta=response.data;

                        me.arreglo_camiones=respuesta;

                        respuesta.map(function(x){
                            
                            me.selectVehiculo.push({text:x.nombre, value:x._id});

                        });

                        me.id_vehiculo_vista=me.selectVehiculo[0].value;



                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });
            },

            Procesar(){



                let diffInMs   = new Date(this.fecha_fin) - new Date(this.fecha_inicio);
                let diffInDays = diffInMs / (1000 * 60 * 60 * 24);


              console.log('dias a generar:'+diffInDays);


              if(diffInDays < 32){

            

              this.arreglo_encendidos=[];  //para indicar cuando se enciende o apaga en la grafica

              this.arreglo_compuerta=[];  //indicara en grafica cuando se abre o cierra

                 let me=this;


                this.limite_max_veh='';
                this.limite_min_veh='';


                  this.arreglo_camiones.map(function(x){
                      if(x._id==me.id_vehiculo_vista){
                        me.limite_max_veh=x.temp_max;
                        me.limite_min_veh=x.temp_min;
                      }
                  });

                console.log('max:'+ me.limite_max_veh+', min:'+ me.limite_min_veh);


              if((this.hora_inicio!='')&&(this.hora_fin!='')&&(this.hora_inicio!=null)&&(this.hora_fin!=null)){


              console.log(this.hora_inicio);
              console.log(this.hora_fin);
             

              this.temp_max=0;
              this.temp_min='';
              this.temp_avg=0;

                this.arregloTemperatura=[];

                this.mostrarLoading({titulo:'Accediendo a datos'});
                let config={headers:{token:this.token}};
                axios.post('posiciones',
                        { 

                        'desde':this.fecha_inicio +' '+this.hora_inicio,
                        'hasta':this.fecha_fin+' '+this.hora_fin,
                        'id_vehiculo':this.id_vehiculo_vista
                        },
                    config
                    ).then(function(response){
                       

                        let respuesta=response.data;

                     


                      

                        let arr_s1=[];
                        let arr_s2=[];
                        let arr_s3=[];

                        let arr_avg=[];

                         me.labels=[];

                        let inicial_s1=0;
                        let inicial_s2=0;
                        let inicial_s3=0;

                        let arr_promedio=[];

                        let sum_temp=0;

                        
                        let motor_on=false;
                        let hora_ence='';
                        let ultima_hora='';


                        let compuerta_on=false;
                        let hora_abiert='';
                     
                  if(diffInDays < 3){

                          me.arregloTemperatura=respuesta;

                         me.arregloTemperatura.map(function(x){


                          if(me.switch1==false){ //convertir a faranheint  
                          
                            if(((x.s1) && (x.s1!='')) ||(x.s1==0)){ x.s1=parseFloat(x.s1) * 1.8 + 32; }
                            if(((x.s2) && (x.s2!='')) ||(x.s2==0)){ x.s2=parseFloat(x.s2) * 1.8 + 32; }
                            if(((x.s3) && (x.s3!=''))||(x.s3==0)){ 
                               x.s3=parseFloat(x.s3) * 1.8 + 32;
                                }
                          }




                           x.fecha=me.getDate(x.fecha);

                       if(diffInDays < 3){

                        if((inicial_s1!=x.s1) || (inicial_s2!=x.s2) || (inicial_s3!=x.s3) ){
                            
                            
                            if((x.estado_compuerta==true)&&(compuerta_on==false)){
                                  hora_abiert=x.hora;
                                  compuerta_on=true;
                            }


                              if((x.estado_compuerta==false)&&(compuerta_on==true)){
                                  me.arreglo_compuerta.push({
                                                          'x':hora_abiert, 
                                                          x2:x.hora,
                                                          fillColor: '#0228d1',
                                                          'borderColor': '#0228d1',
                                                          'style': {
                                                                'color': '#0228d1',
                                                                'background': '#0228d1',
                                                              },
                                                              'offsetY': -10,
                                                              label: {
                                                                    borderColor: '#0228d1',
                                                                    style: {
                                                                      color: '#fff',
                                                                      background: '#0228d1',
                                                                    },
                                                                    offsetY: -10,
                                                                    text: 'Abierta',
                                                                  }
                                                              });

                                  compuerta_on=false;
                              }





                         
                         ultima_hora=x.hora;


                          if((x.estado_encendido==true)&&(motor_on==false)){
                                  hora_ence=x.hora;
                                  motor_on=true;
                          }


                           if((x.estado_encendido==false)&&(motor_on==true)){
                              me.arreglo_encendidos.push({
                                'x':hora_ence, 
                                x2:x.hora,
                                fillColor: '#45b010',
                                'borderColor': '#775DD0',
                                'style': {
                                     
                                      'color': '#45b010',
                                      'background': '#45b010',
                                    },
                                    'offsetY': -10,
                                    label: {
                                           borderColor: '#775DD0',
                                          style: {
                                            color: '#fff',
                                            background: '#775DD0',
                                          },
                                          offsetY: -10,
                                          text: 'Encendido',
                                        }
                                    });

                               motor_on=false;
                           }


                    
                          
                             me.labels.push(x.hora);

                            

                             if(x.s1!=null){arr_s1.push(parseFloat(x.s1).toFixed(2));}
                             if(x.s2!=null){ arr_s2.push(parseFloat(x.s2).toFixed(2));}
                             if(x.s3!=null){arr_s3.push(parseFloat(x.s3).toFixed(2))};

                             if(x.s1!=null && x.s2!=null && x.s3!=null){
                                let valor=parseFloat(x.s1)+parseFloat(x.s2)+parseFloat(x.s3);
                                    valor=valor/3;
                                    arr_avg.push(parseFloat(valor).toFixed(2));

                             } else if(x.s1!=null && x.s2!=null && x.s3==null){

                                 let valor=parseFloat(x.s1)+parseFloat(x.s2);
                                    valor=valor/2;
                                    arr_avg.push(parseFloat(valor).toFixed(2));

                             }else if(x.s1!=null && x.s2==null && x.s3==null){
                                   let valor=parseFloat(x.s1);
                                     arr_avg.push(parseFloat(valor).toFixed(2));

                              }

                             
                              


                              

                           inicial_s1=x.s1;
                           inicial_s2=x.s2;
                           inicial_s3=x.s3;

                           if( parseFloat(x.s1) > me.temp_max){
                                 me.temp_max=parseFloat(x.s1)
                           }

                           if( parseFloat(x.s2) > me.temp_max){
                                 me.temp_max=parseFloat(x.s2)
                           }

                           
                           if( parseFloat(x.s3) > me.temp_max){
                                 me.temp_max=parseFloat(x.s3)
                           }


                           if(x.s1!=null){ arr_promedio.push(x.s1);}
                           if(x.s2!=null){ arr_promedio.push(x.s2);}
                           if(x.s3!=null){ arr_promedio.push(x.s3);}

                          if(x.s1!=null && x.s2!=null && x.s3!=null){ sum_temp=parseFloat(x.s1)+parseFloat(x.s2)+parseFloat(x.s3)+sum_temp;}
                           if(x.s1!=null && x.s2!=null && x.s3==null){ sum_temp=parseFloat(x.s1)+parseFloat(x.s2)+sum_temp;}
                           if(x.s1!=null && x.s2==null && x.s3==null){ sum_temp=parseFloat(x.s1)+sum_temp;}


                            console.log('valor1:'+parseFloat(x.s1)+'valor2:'+parseFloat(x.s2)+' valor3:'+parseFloat(x.s3));

                            console.log('cantidad en array:'+arr_promedio.length+'valor suma:'+sum_temp);

                          

                           if(me.temp_min==''){me.temp_min=x.s1;}

                             if(x.s1!=null){ if(me.temp_min > x.s1){me.temp_min=x.s1;} }
                            if(x.s2!=null){ if(me.temp_min > x.s2){me.temp_min=x.s2;} }
                            if(x.s3!=null){ if(me.temp_min > x.s3){me.temp_min=x.s3;} }

                      
                         }


                         }//fin si hay 2 dias que procesar a detalle


                         
                         });


                            if(diffInDays < 3){


                          //si aun permanece encendido
                          if(motor_on==true){

                            console.log('estaba sin apagar desde las:'+hora_ence)
                             me.arreglo_encendidos.push({
                                'x':hora_ence, 
                                'x2':ultima_hora,
                                 fillColor: '#45b010',
                                'borderColor': '#775DD0',
                                'style': {
                                      'color': '#45b010',
                                      'background': '#45b010',
                                    },
                                    'offsetY': -10,
                                    text: "Encendido",
                                     label: {
                                           borderColor: '#775DD0',
                                          style: {
                                            color: '#fff',
                                            background: '#775DD0',
                                          },
                                          offsetY: -10,
                                          text: 'Encendido',
                                        }

                                    });

                          }



                         me.temp_avg= sum_temp /arr_promedio.length;
                        me.temp_avg=parseFloat(me.temp_avg).toFixed(2);

                        let arrayTotal=me.arreglo_encendidos.concat(me.arreglo_compuerta);

                        let verHoras=true;
                        console.log('canidad etiquetas:'+me.labels.length)

                        // if(me.labels.length >70){
                           //     verHoras=false;
                        // }


                       

                          
                          me.series=[{name:"s1",data:arr_s1},{name:"s2",data:arr_s2},{name:"s3", data:arr_s3},{name:"avg", data:arr_avg}];

                          me.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                 
                                categories: me.labels,
                                tickAmount:14,
                                 title: { text: 'Hora' },
                                 labels: {
                                          show: verHoras,
                                          rotate: -85,
                                          trim:true
                                          }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: arrayTotal
                                 
                              }
                            };

                            }//fin si se llena la grafica con 2 dias u omite


                    }else{


                       let settings = {
                                        fileName: "Sensores", // Name of the resulting spreadsheet
                                        sheetname:'Hoja1'
                                        }

                        let xlsx_datos=[{
                             sheet: "Sensores",
                             columns: [
                                {label:'Vehiculo', value:"id_po_vehiculo.nombre"},
                                {label:'Placa', value:"id_po_vehiculo.placa"},
                                {label:'Flota', value:"id_po_vehiculo.flota"},
                                {label:'Fecha', value:"fecha_texto" },

                                {label:'Estado_encendido', value:"estado_encendido"},
                                {label:'Estado_compuerta', value:"estado_compuerta"},
                                {label:'Latitud', value:"latitud"},
                                {label:'Longitud', value:"longitud"},

                                {label:'S1', value:"s1"},
                                {label:'S2', value:"s2"},
                                {label:'S3', value:"s3"}
                                
                             ],
                              content: respuesta
                        }];


                        xlsx(xlsx_datos, settings);


                    }//si hya que procesar los datos


                          me.ocultarLoading();


                        // console.log(respuesta);
                    });

            }else{

              alert('falta campo horas');
            }


              }else{
                alert('solo puedes consultar intervalos de 1 mes');

              }




            },


            getHora(evaluar) {
                let date = new Date(evaluar),
                hour = date.getHours().toString(),
                formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
                minute = date.getMinutes().toString(),
                formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
                second = date.getSeconds().toString(),
                formatedSecond = (second.length === 1) ? ("0" + second) : second;

                return formatedHour + ":" + formatedMinute + "-" + formatedSecond;
            },

            openGoogleMap(item){
              const urlSuffix = item.latitud +','+ item.longitud;
              window.open(
                  "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                  "_blank"
              );
            },

            getDate(evaluar) {
                let date = new Date(evaluar),
                year = date.getFullYear(),
                month = (date.getMonth() + 1).toString(),
                formatedMonth = (month.length === 1) ? ("0" + month) : month,
                day = date.getDate().toString(),
                formatedDay = (day.length === 1) ? ("0" + day) : day,
                hour = date.getHours().toString(),
                formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
                minute = date.getMinutes().toString(),
                formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
                second = date.getSeconds().toString(),
                formatedSecond = (second.length === 1) ? ("0" + second) : second;
                return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
            },

            VerEncendido(){
              if(this.selectedEncendido==true){

                if(this.selectedCompuerta==true){

                  let arrayTotal=this.arreglo_encendidos.concat(this.arreglo_compuerta);
              
                   this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: arrayTotal
                                 
                              }
                            };

                }else{

                   this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: this.arreglo_encendidos
                                 
                              }
                            };

                }
                  

              }else{
                    if(this.selectedCompuerta==true){

                       this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: this.arreglo_compuerta
                                 
                              }
                            };

                    }else{
                     

                         this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              }
                            };

                    }
                   

              }


            },

            VerCompuerta(){

              if(this.selectedCompuerta==true){

                   if(this.selectedEncendido==true){
                     let arrayTotal=this.arreglo_encendidos.concat(this.arreglo_compuerta);
                  
                         this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: arrayTotal
                                 
                              }
                            };




                   }else{
                    

                       this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: this.arreglo_compuerta
                                 
                              }
                            };



                   }

                  

              }else{

                 if(this.selectedEncendido==true){
                    
                         this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              },
                              annotations: {
                                 xaxis: this.arreglo_encendidos
                                 
                              }
                            };

                      
                      


                    }else{
                    

                        this.chartOptions= {
                              chart: {
                                height: 350, type: 'line',
                                dropShadow: {
                                  enabled: true, color: '#000',
                                  top: 18,left: 7, blur: 10, opacity: 0.2
                                },
                                toolbar: { show: false}
                              },
                              colors: ['#77B6EA', '#0223f7','#f2440a'],
                             dataLabels: { enabled: true },
                              stroke: {  curve: 'smooth'},
                              title: { text: 'Lectura datos', align: 'left'},
                              grid: { borderColor: '#e7e7e7', row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }, },
                              markers: { size: 1 },
                              xaxis: {
                                categories: this.labels,
                                 title: { text: 'Hora' }
                              },
                              yaxis: {
                                title: { text: 'Temperatura' },
                                min: -20, max: 40
                              },
                              legend: {
                                position: 'top', horizontalAlign: 'right', floating: true,
                                offsetY: -25,  offsetX: -5
                              }
                            };



                    }


              }

            },

            cambiarFormatoTemp(){

               if(this.switch1==true){
                  this.switchLabel='°C';
               }else{
                 this.switchLabel='°F';
               }
        

            }

    }
    
    
}
</script>

<style>
.table-container {
  display: flex;
  margin-top: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.flex-table > div {
  width: 100%;
}
</style>
